

export const shippingRegions = [
    // {
    //     id: "global",
    //     name: "All Regions"
    // },
    {
        id: "gb",
        name: "UK (GBP)"
    },
    {
        id: "us",
        name: "US (USD)"
    },
    {
        id: "eu",
        name: "Europe (EUR)"
    },
    {
        id: "row",
        name: "Rest of the world (USD)"
    }
];

export const filterQueryParams = [
    {
        name: "query",
        exports: {
            filters: value => value ? value : undefined
        }
    },
    {
        name: "sale",
        exports: {
            filters: value => value ? value : undefined
        },
        serialize: value => value ?
            typeof value === "object" ? value.value : value :
            "full",
    },
    {
        name: "gender",
        serialize: value => value ?
            typeof value === "object" ? value.value : value :
            "female",
        exports: {
            filters: value => {
                if (!value) {
                    return "female";
                }
                const key = typeof value === "object" ? value.value : value;
                if (!key) {
                    return "female";
                }
                if (key === "all") {
                    return "unisex";
                }
                // return key === "women" ? "female" : "male"
                return key;
            }
        },
        imports: {
            filters: value => {
                if (!value) {
                    return "female";
                }
                return value;
                // return value === "female" ? "women" : "men"
            }
        }
    },
    {
        name: "retailer",
        serialize: list => list && list.length > 0 ? list.join(",") : undefined,
        unserialize: str => str ? str.split(",") : [],
        exports: {
            filters: list => list && list.length > 0 ? list : undefined
        }
    },
    {
        name: "designer",
        serialize: list => list && list.length > 0 ? list.join(",") : undefined,
        unserialize: str => str ? str.split(",") : [],
        imports: {
            filters: (value, data) => {
                return value || data.designer_id;
            }
        },
        exports: {
            filters: [
                "designer_id",
                list => list && list.length > 0 ? list : undefined
            ]
        }
    },
    {
        name: "region",
        serialize: value => value ?
            typeof value === "object" ? value.value : value :
            "gb",
        exports: {
            filters: [
                "_none",
                (value, values) => {
                    value = (value || "").toLowerCase();
                    values["region"] = value;
                    if (value === "us") {
                        values["convert_to_currency"] = "USD";
                    }
                    else if (value === "eu") {
                        values["convert_to_currency"] = "EUR";
                    }
                    else if (value === "gb") {
                        values["convert_to_currency"] = "GBP";
                    }
                    else if (value === "row") {
                        values["convert_to_currency"] = "USD";
                    }
                    return undefined;
                }
            ]
        }
    },
    {
        name: "price",
        serialize: value => value ?
            typeof value === "object" ? value.value : value :
            undefined,
        exports: {
            filters: value => {
                if (!value) {
                    return undefined;
                }
                const key = typeof value === "object" ? value.value : value;
                if (!key || key === "all") {
                    return undefined;
                }
                return key;
            }
        }
    },
    {
        name: "sortby",
        default: "relevance",
        exports: {
            filters: [
                "_none",
                (value, values) => {
                    if (value && value !== "random") {
                        values["order_by"] = value;
                    }
                    else {
                        values["order_by"] = undefined;
                    }
                    return undefined;
                }
            ]
        }
    },
    // {
    //     name: "sortdir",
    //     exports: {
    //         filters: [
    //             "_none",
    //             (value, values) => {
    //                 if (value && value !== "random") {
    //                     values["sort_dir"] = value;
    //                     values["sort_by"] = "price";
    //                 }
    //                 else {
    //                     values["sort_dir"] = undefined;
    //                     values["sort_by"] = undefined;
    //                 }
    //                 return undefined;
    //             }
    //         ]
    //     }
    // }
]
import { Input } from "antd"
import { useCallback, useContext } from "react"
// import Icon from "@ant-design/icons"
import { App as AntApp } from "antd"


import Button from "../button/Button"
import { RetailerSelect } from "common/src/refactor/components/catalogue/filter/Retailer"
import { GenderSelect } from "common/src/refactor/components/catalogue/filter/Gender"
import { DesignerSelect } from "common/src/refactor/components/catalogue/filter/Designer"
import { RegionSelect } from "common/src/refactor/components/catalogue/filter/Region"
import { PriceSelect } from "common/src/refactor/components/catalogue/filter/Price"
import { SaleSelect } from "common/src/refactor/components/catalogue/filter/Sale"

import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
import { ReactComponent as IconClose } from "common/src/svg/close.svg"

import { CatalogueFilterContext } from "common/src/refactor/components/catalogue/Context"


import useDualState from "common/src/refactor/hooks/useDualState"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import { filterQueryParams } from "common/src/refactor/lib/catalogue"
import useInputHandler from "common/src/refactor/hooks/userInputHandler"
// import extractCatalogueId from "common/src/lambdalib/extractCatalogueId"
// import hub from "common/src/hub"

function FeedDialogToolbar({ dialogName, onClose, buttonType = "primary" }) {

    const { modal } = AntApp.useApp();
    const filterContext = useContext(CatalogueFilterContext);
    const onCloseClick = useSwallowEventCallback(
        () => onClose(),
        [onClose]
    );

    const onApplyContext = useCallback(
        ({ filters }) => filterContext.set(filters),
        [filterContext]
    );

    const { query, setQuery,
        retailer, setRetailer,
        gender, setGender,
        designer, setDesigner,
        region, setRegion,
        price, setPrice,
        sale, setSale,
        applyAll } =
        useDualState({
            params: filterQueryParams,
            mode: "state",
            imports: { filters: { ...filterContext.getData() } },
            // param overrides
            overrides: {
                sale: { autoApply: true },
                gender: { autoApply: true },
                designer: { autoApply: true },
                retailer: { autoApply: true },
                region: { autoApply: true },
                price: { autoApply: true },
                sortdir: { autoApply: true }
            },
            onApply: onApplyContext
        });

    const onQueryChange = useInputHandler(setQuery);
    const onSearch = useCallback(
        async (value, e, action) => {

            if (action?.source === "clear") {
                setQuery("");
            }
            applyAll();
        },
        [applyAll, setQuery]
    );

    const onRegionChange = useCallback(
        (value) => {
            setRegion(value);
            modal.info({
                title: "Delivery location updated",
                content: "All products will now be displayed in local currency. Some prices may vary due to taxes and currency updates. Shipping times may vary based on location.",
                closable: false,
                icon: null,
            });
        },
        [setRegion]
    );

    return (
        <div className="feed-dialog-header">
            <div>
                <Input.Search
                    size="large"
                    placeholder="Search by product, brand, retailer or URL"
                    enterButton={
                        <Button
                            // Component="a"
                            onClick={onSearch}
                            size="large"
                            Icon={IconSearch}
                            text="Search"
                            type={buttonType} />
                    }
                    allowClear
                    onChange={onQueryChange}
                    value={query}
                    onSearch={onSearch} />
                {onClose &&
                    <a href="/#"
                        className="feed-dialog-close"
                        onClick={onCloseClick}>
                        <IconClose />
                    </a>}
            </div>
            <div>
                <RegionSelect
                    size="small"
                    labelPrefix="Ships to:"
                    popupMatchSelectWidth={false}
                    value={region}
                    onChange={onRegionChange} />
                <GenderSelect
                    size="small"
                    popupMatchSelectWidth={false}
                    value={gender}
                    onChange={setGender} />
                <DesignerSelect
                    size="small"
                    allowClear
                    mode="multiple"
                    popupMatchSelectWidth={false}
                    value={designer}
                    onChange={setDesigner} />
                <RetailerSelect
                    allowClear
                    size="small"
                    mode="multiple"
                    popupMatchSelectWidth={false}
                    value={retailer}
                    onChange={setRetailer} />


                <PriceSelect
                    size="small"
                    popupMatchSelectWidth={false}
                    value={price}
                    onChange={setPrice} />
                <SaleSelect
                    size="small"
                    popupMatchSelectWidth={false}
                    value={sale}
                    onChange={setSale} />
            </div>
        </div>
    )
}

export default FeedDialogToolbar
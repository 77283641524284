
import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"

const options = [
    {
        key: "full",
        value: "full",
        label: "Full Price"
    },
    {
        key: "sale",
        value: "sale",
        label: "Sale"
    },
    {
        key: "all",
        value: "all",
        label: "All"
    },
]

export function SaleSelect({ className, ...props }) {
    const cls = useMemo(
        () => ["selector-sale", className || ""].join(" "),
        [className]
    );

    return (
        <Select
            className={cls}
            placeholder="Full price and Sale price"
            options={options}
            {...props} />
    )
}

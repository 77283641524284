import { createContext } from "react"
import Context from "common/src/refactor/lib/Context"
import user from "common/src/user"
import { euCountryCodes } from "common/src/lib/countryCodes"

const FilterContext = createContext();

export const CatalogueFilterContext = FilterContext;
export const CatalogueFilterContextProvider = FilterContext.Provider;

export function createCatalogueFilterContext(initialData = {}) {
    if (!("gender" in initialData) || !initialData["gender"]) {
        initialData["gender"] = "female";
    }

    if (!("region" in initialData) || !initialData["region"]) {
        const geo = user.geo();
        const country = geo.country.toLowerCase();

        if (country === "gb") {
            initialData["region"] = "gb";
            initialData["convert_to_currency"] = "GBP";
        }
        else if (country === "us") {
            initialData["region"] = "us";
            initialData["convert_to_currency"] = "USD";
        }
        else if (country in euCountryCodes) {
            initialData["region"] = "eu";
            initialData["convert_to_currency"] = "EUR";
        }
        else {
            initialData["region"] = "row";
            initialData["convert_to_currency"] = "USD";
        }
    }

    if (!("sale" in initialData) || !initialData["sale"]) {
        initialData["sale"] = "full";
    }

    return new Context(initialData)
}
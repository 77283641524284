import { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sets } from "common/src/store/catalogue"
import { catalogueLoader, getProductsBySet } from "common/src/actions/catalogue"
import useDictRef from "common/src/hooks/useDictRef"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import useHub from "common/src/hooks/useHub"
import useProductCatalogueMessages from "./useProductCatalogueMessages"
// import extractCatalogueId from "common/src/lambdalib/extractCatalogueId"

const defaultFilters = {
    // stage: "live",
    // currency: "USD,GBP,EUR",
};

function filters2request(filters) {
    // console.log(filters);
    const request = {};

    if ("gender" in filters && filters["gender"]) {
        request["gender"] = filters["gender"];
    }
    if ("sale" in filters && filters["sale"]) {
        request["sale"] = filters["sale"];
    }

    if ("region" in filters && filters["region"]) {
        request["region"] = filters["region"].toLowerCase();
        const region = filters["region"].toLowerCase();
        if (region === "us") {
            request["convert_to_currency"] = "USD";
        }
        else if (region === "eu") {
            request["convert_to_currency"] = "EUR";
        }
        else if (region === "gb") {
            request["convert_to_currency"] = "GBP";
        }
        else if (region === "row") {
            request["convert_to_currency"] = "USD";
        }
    }
    // if ("sort_dir" in filters && filters["sort_dir"] && filters["sort_dir"] !== "random") {
    //     request["order_by"] = "price_" + filters["sort_dir"];
    // }
    if ("order_by" in filters && filters["order_by"]) {
        request["order_by"] = filters["order_by"];
    }

    if ("query" in filters && filters["query"]) {
        request["query"] = filters["query"];
    }
    if ("retailer" in filters && filters["retailer"]) {
        request["retailer"] = filters["retailer"];
    }
    if ("designer_id" in filters && filters["designer_id"]) {
        request["designer"] = filters["designer_id"];
    }
    if ("price" in filters && filters["price"]) {
        request["price"] = filters["price"];
    }


    // console.log(request);
    return request;
}

function useProductCatalogue({ filters, setName, clearOnUnmount = true, perPage = 36 }) {

    const dispatch = useDispatch();
    const puids = useSelector(s => s.catalogue.sets.products[setName]);
    const geo = useSelector(s => s.user.geo || {});
    const currency = geo.currency;
    const originalCurrency = geo.original || false;
    const { loading = true,
        loaded = false,
        page = 0,
        hasMore } = useSelector(s => s.catalogue.ui.products.misc[setName] || {});
    const [products, setProducts] = useState(() => getProductsBySet(setName));
    // const [productWebId, setProductWebId] = useState(null);
    // console.log(filters)

    const ref = useDictRef({
        clearOnUnmount, setName,
        filters, page, perPage,
        originalCurrency, currency,
        // productWebId
    });

    //const { hasResettingChanges, hasChanges } = useProductFilters(filters);

    const load = useCallback(
        () => {
            // const cat = extractCatalogueId(filters?.query || "");

            // if (cat?.catalogueId) {
            //     const { query, ...filters } = ref.filters;
            //     catalogueLoader({
            //         ...defaultFilters,
            //         // displayCurrency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
            //         convert_to_currency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
            //         // ...filters,
            //         append: false,
            //         // page: 0,
            //         // perPage: ref.perPage,
            //         setName: ref.setName,
            //         product_web_id: cat.catalogueId
            //     });
            // }
            // else {
            catalogueLoader({
                ...defaultFilters,
                ...filters2request(ref.filters),
                append: false,
                page: 0,
                perPage: ref.perPage,
                setName: ref.setName
            });
            // }

            // setProductWebId(cat?.catalogueId || null);

        },

        [ref, filters]
        //productWebId,
    );

    useHub("catalogue-loader", "productWebId-set", load);

    const reload = useCallback(
        () => {
            load();
        },
        []
    );

    const loadMore = useCallback(
        () => {
            // console.log("loadMore");
            // return;
            // if (ref.productWebId) {
            //     catalogueLoader({
            //         ...defaultFilters,
            //         convert_to_currency: ref.originalCurrency ? undefined : ref.currency.toUpperCase(),
            //         // ...ref.filters,
            //         append: true,
            //         // page: ref.page + 1,
            //         // perPage,
            //         setName,
            //         product_web_id: ref.productWebId
            //     });
            // } else {
            catalogueLoader({
                ...defaultFilters,
                ...filters2request(ref.filters),
                append: true,
                page: ref.page + 1,
                perPage,
                setName,
            });
            // }
        },
        []
        //ref.productWebId
    );

    const loadPage = useCallback(
        (page) => {
            console.log("loadPage", page);
            return;
            catalogueLoader({
                // ...ref.filters,
                append: false,
                // page,
                // perPage,
                setName
            });
        },
        []
    );

    const messages = useProductCatalogueMessages({ filters, products, setName });

    useUpdateEffect(
        () => setProducts(getProductsBySet(ref.setName)),
        [puids]
    );

    useUpdateEffect(
        () => {
            load();
        },
        [filters, currency, originalCurrency]
    );

    useEffect(
        () => {
            load();
            return () => {
                if (ref.clearOnUnmount) {
                    dispatch(sets.products.unset(ref.setName));
                }
            }
        },
        []
    );


    return {
        loading, products, reload,
        loadMore, loadPage, page, hasMore, loaded,
        messages
    };
}

export default useProductCatalogue
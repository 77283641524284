
import prepareImgInfo from "./info"

export default function (srcs, resolveWithInfo = true) {
    const promises = srcs.map(src => new Promise((resolve, reject) => {
        if (!src) {
            resolve(null);
        }
        let img = new Image();
        img.onload = () => resolve(resolveWithInfo ? prepareImgInfo(img) : null);
        img.onerror = () => resolve(null);
        img.src = src;
    }));

    return Promise.allSettled(promises)
        // remove failed images
        .then(results => results.map(r => r.value))
        .then(imgs => imgs.filter(img => !!img));
}
import { useMemo, useCallback, useContext, useState, useRef } from "react"
import { Spin } from "antd"

import Button from "common/src/refactor/components/button/Button"

import Spacer from "common/src/refactor/components/Spacer"
import FeedDialogToolbar from "common/src/refactor/components/catalogue/FeedDialogToolbar"
import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductSelect from "common/src/refactor/components/catalogue/product/Select"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg"

import useContextData from "common/src/refactor/hooks/useContextData"
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue"
import useDictRef from "common/src/hooks/useDictRef"
import { CatalogueFilterContextProvider, createCatalogueFilterContext }
    from "common/src/refactor/components/catalogue/Context"
import LookBuilderContext from "../LookBuilderContext"
import useSelection from "common/src/refactor/hooks/useSelection"
import * as productEditor from "common/src/actions/look/product"
import useUpdateEffect from "common/src/hooks/useUpdateEffect"
import async from "common/src/lib/js/async"
import useThrottleCallback from "common/src/hooks/useThrottleCallback"
import { Portal } from "common/src/refactor/components/Portal"
import BackToTop from "common/src/refactor/components/catalogue/BackToTop"
import FeedMessages from "common/src/refactor/components/catalogue/FeedMessages"

function isRetailerSearch(query, retailer) {
    return query ? query.indexOf(retailer) !== -1 : false
}

function LookBuilderCatalogue({ open = false }) {

    const builder = useContext(LookBuilderContext);
    const filterContext = useMemo(() => createCatalogueFilterContext(), []);
    const filters = useContextData(filterContext);
    const { selection, toggle: toggleSelection, isSelected } = useSelection({ multiple: false });
    const selectionCount = useMemo(() => Object.keys(selection).length, [selection]);
    const { products, loadMore, hasMore, loading, loaded, messages } =
        useProductCatalogue({ filters, setName: "look-builder", clearOnUnmount: false });
    const ref = useDictRef({ hasMore, loading, loadMore });
    const [adding, setAdding] = useState(false);
    const scrollRef = useRef();

    const onProductClick = useCallback(
        async (product) => {
            toggleSelection(product.uid)
            // product = productEditor.normalizeCatalogueProduct(product);

            // if (product.images) {
            //     product.images = await productEditor.preloadProductImages(product.images);
            // }

            // builder.trigger("search-select-product", product);

        },
        [toggleSelection]
    );

    const onSelectorChange = useCallback(
        (id, state) => {
            //const product = products.find(p => p.uid === id);
            toggleSelection(id);
        },
        [toggleSelection]
    );

    const onAddProductClick = useCallback(
        async () => {
            setAdding(true);
            const sel = selection.map(uid => products.find(p => p.uid === uid));
            let product = sel[0];
            if (product) {
                product = productEditor.normalizeCatalogueProduct(product);

                if (product.images) {
                    product.images = await productEditor.preloadProductImages(product.images);
                }

                builder.trigger("search-select-product", product);
            }
            setAdding(false);
        },
        [selection, builder, products]
    );

    const onScroll = useThrottleCallback(
        (e) => {
            const el = e.target;
            ref.scrollTop = el.scrollTop;
            if (ref.hasMore && !ref.loading) {
                if (el.scrollTop / (el.scrollHeight - el.offsetHeight) > 0.75) {
                    ref.loadMore();
                }
            }
        },
        // eslint-disable-next-line
        [],
        50,
        {
            trailing: true
        }
    );

    /*const onAddToMoodboardClick = useCallback(
        async () => {
            //const sel = selection.map(uid => products.find(p => p.uid === uid));
            const sel = [];
            setAdding(true);
            for (const uid of selection) {
                let product = products.find(p => p.uid === uid);
                product = productEditor.normalizeCatalogueProduct(product);
                if (product.images) {
                    product.images = await productEditor.preloadProductImages(product.images);
                }
                sel.push(product);
            }

            setAdding(false);
            builder.set("addToMoodboardProducts", sel);
            builder.openPage("add-to-moodboard");
        },
        [ selection, products, builder ]
    );*/

    useUpdateEffect(
        () => {
            if (open && scrollRef.current && ref.scrollTop) {
                async(() => scrollRef.current.scrollTo({ top: ref.scrollTop }));
            }
        },
        [open]
    );

    if (!open) {
        return null;
    }

    return (
        <div className="look-builder-search-catalogue">
            <CatalogueFilterContextProvider value={filterContext}>
                <Spacer size="0rem" />
                <FeedDialogToolbar buttonType="secondary" />
                <FeedMessages messages={messages} />

                <div className="feed-dialog-body" onScroll={onScroll} ref={scrollRef}>
                    {(loaded && !loading && products.length === 0 && !isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage size="small" message="No products matched your search" />}
                    {(loaded && !loading && products.length === 0 && isRetailerSearch(filters.query, "bottegaveneta")) && <EmptyMessage size="small" message="Search via URL is currently not available from Bottega Veneta website. Please use text search or filter by retailer." />}
                    <div className="feed-dialog-products">
                        {products.map(p => (
                            <ProductCard
                                key={p.uid}
                                product={p}
                                onClick={onProductClick}
                                children={
                                    <CatalogueProductSelect
                                        id={p.uid}
                                        selected={isSelected(p.uid)}
                                        onChange={onSelectorChange} />
                                } />))}
                    </div>
                    {(hasMore || !loaded) && <Spin spinning className="infinite-scroll-spinner" />}
                </div>
                <Portal host="look-builder-search-footer">
                    <div className="look-builder-dialog-footer">
                        <Button
                            loading={adding}
                            disabled={selectionCount === 0 || adding}
                            text="Add to look"
                            type="primary"
                            size="large"
                            onClick={onAddProductClick} />
                        <BackToTop elemRef={scrollRef}>
                            <Button
                                type="text"
                                size="medium"
                                text="Back to top"
                                variant="link"
                                Icon={IconUp}
                                iconPosition="after" />
                        </BackToTop>
                    </div>
                </Portal>
                {/* selectionCount > 0 && 
                <div className="look-builder-search-catalogue-moodboards">
                    <Button 
                        variant="full-width"
                        type="secondary"
                        loading={ adding }
                        disabled={ adding }
                        onClick={ onAddToMoodboardClick }
                        Icon={ IconHeart }
                        text="Add selection to my Moodboard"/>
                        </div> */}
            </CatalogueFilterContextProvider>
        </div>
    )
}

export default LookBuilderCatalogue

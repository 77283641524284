import { useMemo } from "react"
import { useSelector } from "react-redux"

import Select from "common/src/refactor/components/ant/Select"

const sorting = [
    { value: "relevance", name: "Recommended" },
    { value: "price_asc", name: "Price (low-high)" },
    { value: "price_desc", name: "Price (high-low)" },
]

export function SortBySelect({ className, currency, ...props }) {

    const loading = useSelector(s => s.catalogue.ui.loading);

    const options = useMemo(
        () => sorting.map(p => ({
            key: "" + p.value,
            value: p.value,
            label: p.name
        })),
        []
    );

    const cls = useMemo(
        () => ["selector-price", className || ""].join(" "),
        [className]
    );

    return (
        <Select
            className={cls}
            placeholder="Sort by"
            options={options}
            loading={loading}
            {...props} />
    )
}

import user from "common/src/user"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { country2region, region2name, region2currency } from "common/src/lib/countryCodes";


export default function useProductCatalogueMessages({ filters, products, setName }) {

    const [messages, setMessages] = useState([]);
    const userCountry = user.geo()?.country?.toLowerCase();
    const userRegion = country2region(userCountry);
    const filterRegion = filters?.region?.toLowerCase();
    const { loading = true } = useSelector(s => s.catalogue.ui.products.misc[setName] || {});

    useEffect(
        () => {
            setTimeout(
                () => {
                    const messages = [];
                    const targetCurrency = region2currency[filterRegion];
                    const targetRegion = region2name[filterRegion];
                    if (filterRegion) {
                        if (filterRegion !== "global" && filterRegion !== userRegion) {
                            messages.push({
                                type: "prices",
                                message: `Prices are displayed in ${targetCurrency} as your delivery location is set to the ${targetRegion}. Some prices may vary due to taxes and currency updates.`
                            });
                        }

                        if (!loading && filterRegion !== "global") {
                            if (products && products.length > 0) {
                                let currentRegionFound = false;
                                let anyRegionFound = false;
                                products.forEach(product => {
                                    if (product.region) {
                                        anyRegionFound = true;
                                    }
                                    if (product.region?.toLowerCase() === filterRegion) {
                                        currentRegionFound = true;
                                    }
                                });
                                if (anyRegionFound && !currentRegionFound) {
                                    messages.push({
                                        type: "region",
                                        title: `No results for this location.`,
                                        message: filterRegion === 'row' ?
                                            'See below for options that ship outside the chosen region' :
                                            `See below for options that ship outside the ${targetRegion}`
                                    });
                                }
                            }
                        }
                    }
                    setMessages(messages);
                },
            )
        },
        [filterRegion, userRegion, products, loading]
    );
    return messages;
}
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"

import Select from "common/src/refactor/components/ant/Select"

// import formatCurrency from "common/src/lib/format/currency"
// import { loadFacets } from "common/src/actions/catalogue"

const priceRanges = [
    { value: "<250", name: " <250" },
    { value: "250-500", name: " 250-500" },
    { value: "500-1000", name: " 500-1000" },
    { value: "1000-2000", name: " 1000-2000" },
    { value: "2000-5000", name: " 2000-5000" },
    { value: "5000-10000", name: " 5000-10000" },
    { value: "10000-20000", name: " 10000-20000" },
    { value: ">20000", name: " 20000+" },
]

export function PriceSelect({ className, currency, ...props }) {

    // const prices = useSelector(s => s.catalogue.data.prices);
    const userCurrency = useSelector(s => s.user.geo.currency);
    currency = currency || userCurrency;
    const loading = useSelector(s => s.catalogue.ui.loading);

    const options = useMemo(
        () => [{ key: "all", value: "", label: "All" },
        ...priceRanges.map(p => ({
            key: "" + p.value,
            value: currency.toLowerCase() + "/" + p.value,
            label: currency + p.name
            // label: `Up to ${ formatCurrency(p, currency) }`
        }))],
        [currency]
    );

    const cls = useMemo(
        () => ["selector-price", className || ""].join(" "),
        [className]
    );


    return (
        <Select
            className={cls}
            placeholder="Price"
            options={options}
            loading={loading}
            {...props} />
    )
}
